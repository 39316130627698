import React, { useState } from 'react';
import { Card, Button, Container, Row, Col } from 'react-bootstrap';

function Projects() {
    const [visibleProjects, setVisibleProjects] = useState(4);

    const projects = [
        {
            title: "Swanky Storage",
            description: "A Craigslist style web app allowing users to post, view, and contact about object listings. Features include account management, chat, and listing categorization.",
            techStack: "JavaScript, HTML, CSS, React, MongoDB",
            link: "https://github.com/ShaikeMukul/cs179-project-swankystorage"
        },
        {
            title: "LionJet",
            description: "Document data storage software focused on managing patient details. Includes CRUD functionality and a secure login system.",
            techStack: "React, C++, Crow Middleware",
            link: "https://github.com/ShaikeMukul/cs180-21-LionJet"
        },
        {
            title: "Feature Selection with Nearest Neighbor",
            description: "A feature selection project in Python that analyzes the impact of feature selection on the Nearest Neighbor Classifier.",
            techStack: "Python",
            link: "https://github.com/ShaikeMukul/Project2_Intro_Feature_Selection_with_Nearest_Neighbor_hobra001_linaguz020_smuku004_apena080"
        },
        {
            title: "8-Puzzle Solver",
            description: "A versatile program to solve the 8-Puzzle problem using Uniform Cost Search, A* with Misplaced Tile heuristic, and A* with Euclidean Distance heuristic.",
            techStack: "Python",
            link: "https://github.com/ShaikeMukul/Project1_8_Puzzle_hobra001_linaguz020_smuku004_apena080"
        },
        {
            title: "The Dark Dungeon",
            description: "A text-based RPG game where players navigate through challenges using strategic gameplay.",
            techStack: "C++, Valgrind, Git/Github, GoogleTest, CMake/Make",
            link: "https://github.com/ShaikeMukul/cs100-final-project-eden-marissa-isean-shaike"
        },
    ];

    const showMoreProjects = () => {
        setVisibleProjects(projects.length);
    };

    return (
        <section id="projects" className="p-5 bg-light">
            <Container>
                <h2>Projects</h2>
                <Row>
                    {projects.slice(0, visibleProjects).map((project, index) => (
                        <Col key={index} md={3} className="mb-4">
                            <Card className="h-100 d-flex flex-column">
                                <Card.Body className="d-flex flex-column">
                                    <Card.Title>{project.title}</Card.Title>
                                    <Card.Text>{project.description}</Card.Text>
                                    <Card.Text className="mt-auto"><strong>Tech Stack:</strong> {project.techStack}</Card.Text>
                                </Card.Body>
                                <Card.Footer className="bg-white border-0">
                                    <Button variant="primary" href={project.link} target="_blank" className="w-100">View Project</Button>
                                </Card.Footer>
                            </Card>
                        </Col>
                    ))}
                </Row>
                {visibleProjects < projects.length && (
                    <div className="text-center">
                        <Button variant="secondary" onClick={showMoreProjects}>
                            See All
                        </Button>
                    </div>
                )}
            </Container>
        </section>
    );
}

export default Projects;
