import React from 'react';

function About() {
    return (
        <section id="about" className="p-5">
            <div className="container">
                <h2>About Me</h2>
                <p>
                    Driven and enthusiastic Computer Engineering graduate looking for opportunities to leverage my technical skills and passion for innovation.
                </p>
            </div>
        </section>
    );
}

export default About;
