import React from 'react';

function Certifications() {
    return (
        <section id="certifications" className="p-5">
            <div className="container">
                <h2>Certifications</h2>
                <ul>
                    <li><strong>Python for Everybody</strong> - University of Michigan (August 2021)</li>
                </ul>
            </div>
        </section>
    );
}

export default Certifications;
