import React from 'react';

function Education() {
    return (
        <section id="education" className="p-5 bg-light">
            <div className="container">
                <h2>Education</h2>
                <ul>
                    <li><strong>University of California, Riverside</strong> - Bachelor of Science in Computer Engineering (September 2023)</li>
                    <li><strong>Chaffey College</strong> - Associate of Science in Computer Science and Physics (May 2021)</li>
                </ul>
            </div>
        </section>
    );
}

export default Education;
