import React from 'react';

function Footer() {
    return (
        <footer className="p-3 bg-dark text-white text-center">
            <div className="container">
                <p>&copy; 2024 Shaike Mukul</p>
                <p><a href="https://github.com/ShaikeMukul" target="_blank" rel="noopener noreferrer">GitHub</a> | <a href="https://linkedin.com/in/ShaikeMukul" target="_blank" rel="noopener noreferrer">LinkedIn</a></p>
            </div>
        </footer>
    );
}

export default Footer;
