import React from 'react';

function Awards() {
    return (
        <section id="awards" className="p-5 bg-light">
            <div className="container">
                <h2>Honors & Awards</h2>
                <ul>
                    <li><strong>Chaffey College Foundation Scholarship</strong> (December 2020)</li>
                    <li><strong>Member of Phi Theta Kappa Honors Society</strong> (November 2019)</li>
                </ul>
            </div>
        </section>
    );
}

export default Awards;
