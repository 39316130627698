import React from 'react';
import './Skills.css';

function Skills() {
    return (
        <section id="skills" className="skills-section">
            <div className="container">
                <h2>Skills</h2>
                <ul className="skills-list">
                    <li className="skills-item">C++</li>
                    <li className="skills-item">Python</li>
                    <li className="skills-item">JavaScript</li>
                    <li className="skills-item">PostgreSQL</li>
                    <li className="skills-item">MongoDB</li>
                    <li className="skills-item">Node</li>
                    <li className="skills-item">Express</li>
                    <li className="skills-item">HTML5</li>
                    <li className="skills-item">CSS3</li>
                    <li className="skills-item">SASS</li>
                    <li className="skills-item">Bootstrap</li>
                    <li className="skills-item">React</li>
                    <li className="skills-item">Windows</li>
                    <li className="skills-item">MacOS</li>
                    <li className="skills-item">Linux</li>
                    <li className="skills-item">Git</li>
                    <li className="skills-item">GitHub</li>
                    <li className="skills-item">LaTeX</li>
                </ul>
            </div>
        </section>
    );
}

export default Skills;
